<template>
  <div class="wet-module-container">
    <img src="../../assets/img/vertical-dots.svg" class="vertical-img-bg" alt="dots-bg-img">
    <Menu></Menu>
    <section class="__section">
      <h1>{{ data.title}}</h1>
      <form class="md needs-validation is-valid d-flex flex-column" @submit="send">
        <div class="form-group wet-form mt-3">
          <label class="form-label">{{ data.fields.password.text }}</label>
          <input v-model="Form.password" :placeholder="data.fields.password.placeholder" type="password"
                 class="form-control" required>
        </div>
        <div class="form-group wet-form mt-2">
          <label class="form-label">{{data.fields.repeatedPassword.text}}</label>
          <input v-model="Form.passwordConfirmed" :placeholder="data.fields.repeatedPassword.placeholder" type="password"
                 class="form-control" required>
        </div>
        <button class="btn btn-success btn-lg mt-5" type="submit">
          {{data.actionButtonText}}
        </button>
      </form>
    </section>
  </div>
</template>
<script>
import Menu from "@/components/layout/Menu.vue";
import {passwordReset} from "../../helpers/auth";
import store from "../../store";

export default {
  components: {Menu},
  beforeDestroy() {

  },

  created() {
    this.route = this.$route.params.key;
  },

  data() {
    return {
      route: null,
      data: {
        title: "Restablecer contraseña",
        actionButtonText: 'Aceptar',
        fields: {
          password: {text: "Contraseña", placeholder: "Ingrese su contraseña"},
          repeatedPassword: {text: "Repita la nueva contraseña", placeholder: "Repita su contraseña"},
        }
      },
      Form: {
        key: this.$route.params.key,
        password: null,
        passwordConfirmed: null
      }
    }
  },

  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function() {
      this.fetchData();
    }
  },

  methods: {
    fetchData() {
      if (store.state.sections.passwordReset) {
        this.data = {...store.state.sections.passwordReset.components};}
    },
    async send (e) {
      e.preventDefault();
      const body = { password: this.Form.password, id: this.route, language: this.$store.getters.getLanguage }
      const response = await passwordReset(body);
      if  (response.status === 200) {
        this.$router.push('/login')
      }
    }
  },
}
</script>
<style lang="scss">
@media (min-width: 1200px) {
  .__section {
    margin: 0 !important;

    form.md {
      max-width: 756px;
    }

  }
  .wet-module-container {
    justify-content: center;
  }
}
</style>
